




















































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchCreateSiteParserTemplate,
  dispatchGetSiteParserTemplates,
} from '@/store/site-parser-template/actions';
import {
  ISiteTemplate,
  ISiteTemplateCreate,
  siteTemplateInit,
} from '@/interfaces/site-parser-template';
import { nameRules } from './validators';

@Component
export default class SiteParserTemplateEdit extends Vue {
  public submitting = false;
  public valid = false;
  public currentTemplate: ISiteTemplate = { ...siteTemplateInit };
  public sitePlugsStr: string = '';
  public nameRules = nameRules;

  public async mounted() {
    this.reset();  // чтобы сразу показать в полях значения, которые уже загружены
    await dispatchGetSiteParserTemplates(this.$store);
    this.reset();  // обновляем значения полей, если они изменились
  }

  public reset() {
    this.currentTemplate = { ...siteTemplateInit };
    this.sitePlugsStr = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const newTemplate: ISiteTemplateCreate = {
        ...this.currentTemplate,
        book: {
          link: {...this.currentTemplate.book.link},
          title: {...this.currentTemplate.book.title},
          author: {...this.currentTemplate.book.author},
        },
        sitePlugs: this.deserializeSitePlugs,
      };
      await dispatchCreateSiteParserTemplate(this.$store, newTemplate);
      this.$router.push('/main/site-parser-template');
    }
  }

  get serializedSitePlugs() {
    return this.currentTemplate.sitePlugs.join('\n');
  }

  get deserializeSitePlugs() {
    return this.sitePlugsStr.split('\n');
  }

}

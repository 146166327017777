export interface ISiteTemplate {
  domain: string;
  baseUrl: string;
  bookContainer: string;
  card: string;
  book: IBookDataSelector;
  nextPageSelector: string;
  sitePlugs: string[];
  pageNavUrl: string;
  sitePlugsSelector: string;
}

export interface ISiteTemplateCreate {
  domain: string;
  baseUrl: string;
  bookContainer: string;
  card: string;
  book: IBookDataSelector;
  nextPageSelector: string;
  sitePlugs: string[];
  pageNavUrl: string;
  sitePlugsSelector: string;
}

export interface ISiteTemplateUpdate {
  baseUrl: string;
  bookContainer: string;
  card: string;
  book: IBookDataSelector;
  nextPageSelector: string;
  sitePlugs: string[];
  pageNavUrl: string;
  sitePlugsSelector: string;
}

export interface ISelectorMetadata {
  sel: string;
  attr: string;
  reg: string;
}

export interface IBookDataSelector {
  [key: string]: ISelectorMetadata;
  link: ISelectorMetadata;
  title: ISelectorMetadata;
  author: ISelectorMetadata;
}

export const selectorMetadataInit: ISelectorMetadata = { sel: '', attr: '', reg: '' };

export const bookDataSelectorInit: IBookDataSelector = {
  link: {...selectorMetadataInit}, title: {...selectorMetadataInit}, author: {...selectorMetadataInit},
};

export const siteTemplateInit: ISiteTemplate = {
  domain: '',
  baseUrl: '',
  bookContainer: '',
  card: '',
  book: bookDataSelectorInit,
  nextPageSelector: '',
  sitePlugs: [],
  pageNavUrl: '',
  sitePlugsSelector: '',
};
